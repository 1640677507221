import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  QuillModule as OriginalQuillModule,
  QUILL_CONFIG_TOKEN,
  QuillConfig,
} from 'ngx-quill';
import { DomSanitizer } from '@angular/platform-browser';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { QuillDomSanitizer } from './quill.domsanitizer';
import Quill, { StringMap } from 'quill';
import { VarPlaceholderBlot } from './var-placeholder/var-placeholder.blot';
import { QuillComponent } from './quill.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VarPlaceholderComponent } from './var-placeholder/var-placeholder.component';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { NewTemplateDialogComponent } from './var-placeholder/new-template-dialog/new-template-dialog.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslationModule } from '@clients/helper';
// @ts-ignore
import ImageCompress from 'quill-image-compress';
import { QUILL_MODULES } from './consts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    OriginalQuillModule,
    ReactiveFormsModule,
    MatIconModule,
    FlexLayoutModule,
    MatInputModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    TranslationModule.forChild('shared'),
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    QuillComponent,
    VarPlaceholderComponent,
    NewTemplateDialogComponent,
  ],
  exports: [QuillComponent],
})
export class QuillModule {
  static forRoot(): ModuleWithProviders<QuillModule> {
    const customs: StringMap = {};
    customs[`formats/${VarPlaceholderBlot.blotName}`] = VarPlaceholderBlot;
    customs['modules/quill-image-compress'] = ImageCompress;
    Quill.register(customs, true);

    return {
      ngModule: QuillModule,
      providers: [
        {
          provide: QUILL_CONFIG_TOKEN,
          useValue: <QuillConfig>{
            placeholder: '',
            modules: QUILL_MODULES,
          },
        },
        {
          provide: DomSanitizer,
          useFactory: (sanitizer: NgDompurifySanitizer) =>
            new QuillDomSanitizer(sanitizer),
          deps: [NgDompurifySanitizer],
        },
      ],
    };
  }
}
