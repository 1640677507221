<form [formGroup]="formGroup"
      gdAreas="default-lang | actions"
      gdRows="1fr auto"
      gdColumns="minmax(0, 1fr)"
      gdGap="8px 16px"  *transloco="let t">
  <shared-quill
    gdArea="default-lang"
    class="full-width full-height"
    [hideRephraseButton]="true"
    [formGroup]="formGroup"
    [controlName]="'text'"
    [hide]="false"
  ></shared-quill>

  <div gdArea="actions" mat-dialog-actions fxLayout fxLayoutAlign="end" fxLayoutGap="16px">
    <button mat-raised-button [mat-dialog-close]="undefined">
      {{ t('app.general.cancel') }}
    </button>
    <button mat-raised-button (click)="closeDialog(true)">
      {{ t('app.general.confirm') }}
    </button>
  </div>
</form>

