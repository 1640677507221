<div *ngIf="!readonly" class="full-height" style="display: flex" [formGroup]="formGroup">
  <quill-editor
    gdArea="input"
    class="full-width full-height"
    [formControlName]="controlName"
    format="html"
    [sanitize]="true"
    [modules]="quillModules"
  >
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <select class="ql-size" *transloco="let t; read: 'shared.quill.sizes'">
          <option value="small">{{ t('small') }}</option>
          <option selected>{{ t('normal') }}</option>
          <option value="large">{{ t('large') }}</option>
          <option value="huge">{{ t('huge') }}</option>
        </select>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-bold"></button>
        <button type="button" class="ql-italic"></button>
        <button type="button" class="ql-underline"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <select class="ql-align"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-link"></button>
        <button type="button" class="ql-image"></button>
      </span>
      <span class="ql-formats" *transloco="let t; read: 'shared.quill.insert'">
        <button
          *ngFor="let type of varTypes"
          type="button"
          class="insert-button"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="4px"
          (click)="insertTemplate(type)"
        >
          <img [src]="'/assets/placeholder-icons/' + type + '.png'" />
          <span>{{ t(type) }}</span>
        </button>
        <button
          *ngIf="!hideRephraseButton"
          type="button"
          class="insert-button aiButton"
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="4px"
          (click)="rephraseText.emit(controlName)"
        >
          <img [src]="'/assets/placeholder-icons/magic-wand.png'" height="18px" width="18px" alt="ai rephrase"/>
          <span>{{ t('rephrase') }}</span>
          <mat-spinner *ngIf="rephraseLoading" diameter="18"></mat-spinner>
        </button>
      </span>
    </div>
  </quill-editor>
</div>

<quill-view
  *ngIf="readonly"
  gdArea="input"
  class="full-width full-height"
  [content]="readonlyContent"
  format="html"
  [sanitize]="true"
></quill-view>

<!-- required for drawing icon on canvas for placeholder -->
<div style="display: none">
  <img
    *ngFor="let type of varTypes | keyvalue"
    [id]="placeholderIconIdPrefix + type.value"
    [src]="'/assets/placeholder-icons/' + type.value + '.png'"
  />
</div>
