import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiTextDialogComponent } from './ai-text-dialog.component';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { QuillModule } from '@clients/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [AiTextDialogComponent],
  imports: [
    CommonModule,
    GridModule,
    QuillModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    TranslocoModule,
  ],
  exports: [AiTextDialogComponent],
})
export class AiTextDialogModule {}
